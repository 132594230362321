<template>
  <aside class="navigation">
    <BlueprintBlackLogo class="logo" />
    <div style="display: flex; align-items: center; margin-bottom: 50px;">
      <span v-if="!store.state.user.leader" class="role-name">{{getRole()}}</span>
      <span v-if="store.state.user.leader" class="role-name">
<select v-model="view" 
class="select"
name="" id=""
>
  <option :selected="true" :value="store.state.user.role">{{getRole()}}</option>
  <option value="leader">Leader</option>
</select>    
  </span>
      <div class="divider" />
    </div>
    <div v-if="view!=='leader'">
    <div
      v-for="tab in tabs"
      :key="tab.name"
      class="tab"
      :class="{active: tab.path === currentTab}"
      @click="tabClickHandler(tab.path)"
    >
      <div class="tab-icon" v-html="tab.icon" :style="{fill: activeTab === tab.path ? '#FFF' : '#FF8217'}" />
      <span class="tab-name">{{tab.name}}</span>
    </div>
    </div>
    <!-- <div
      class="tab"
      @click="handleExternal()"
    > -->
    <div v-if="view==='leader'">
    <div
      v-for="tab in leaderTabs"
      :key="tab.name"
      class="tab"
      :class="{active: tab.path === currentTab}"
      @click="tabClickHandler(tab.path)"
    >
      <div class="tab-icon" v-html="tab.icon" :style="{fill: activeTab === tab.path ? '#FFF' : '#FF8217'}" />
      <span class="tab-name">{{tab.name}}</span>
    </div>
    </div>
    <div
      class="tab"
      @click="handleExternal()"
    >
      <div class="tab-icon" v-html="support" :style="{fill: '#FF8217'}" />
      <span class="tab-name">Support</span>
    </div>
    <div class="tab" @click="signOutHandler">
      <img :src="signOutIcon" class="tab-icon" alt="icon" style="width: 29px" />
      <span class="tab-name">Sign Out</span>
    </div>
  </aside>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import BlueprintBlackLogo from '@/components/ui/BlueprintBlackLogo'
import signOutIcon from '@/assets/icons/sign-out.svg'
import { useCookies } from '@vueuse/integrations/useCookies'
import support from '@/assets/icons/support'

const { remove } = useCookies(['onboarding_journey'], { doNotParse: false, autoUpdateDependencies: false })
const view = ref('')
const router = useRouter()
const route = useRoute()
onBeforeMount(()=>{
  store.state.user.role = 'AISI'
  view.value = store.state.user.role
  console.log('view:',view.value)
})
console.log('######', route.path)
const store = useStore()
const props = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  leaderTabs: {
    type: Array,
    required: true
  },
  currentTab: {
    required: true
  }
})
const handleExternal = () => {
    window.open('https://support.hdisonboarding.com', '_blank')

}
const getRole = ()=>{
if(store.state.user.role==='AISI') return 'AIS–I' 
if(store.state.user.role==='AISE') return 'AIS–E'

}
console.log('propps:', props)
const emit = defineEmits({
  tabClick: (path) => typeof path === 'string',
  signOut: () => true
})

// const activeTab = ref(route.path)

const tabClickHandler = (path) => {
  // activeTab.value = path
  emit('tabClick', path)
}

const signOutHandler = async () => {
  await remove('onboarding_journey', {path:'/', domain:'.hdisonboarding.com'})
document.cookie = "onboarding_journey=; path=/; domain=.hdisonboarding.com;";
  emit('tabClick', '/login')
}
const changeView = (e) => {
  // console.log('???????:',e.target.value)
  // view.value = e.target.value
  if(e.target.value = 'leader'){
    tabClickHandler('/new-hires')
    } else {
    tabClickHandler('/')

  }

}
// tabClickHandler(props.currentTab)

</script>

<style scoped>
.navigation {
  width: 300px;
  padding: 50px 15px 10px;
  background: white;
}
.select{
  color: var(--hd-orange, #F96302);
font-size: 14px;
font-family: Helvetica Neue LT Std;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
border-radius: 5px;
border: none;
background: var(--system-light-grey, #F4F6F5);
padding: 10px;
}
.logo {
width: 215px;
/* height: 141px; */
padding: 0 20px 30px;
}

.role-name {
  margin-left: 20px;
  margin-right: 10px;
  text-transform: uppercase;
  color: #FF8217;
}

.divider {
  width: 45%;
  height: 1px;
  background: #e5e5e5;
}

.tab {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 15px;
  padding: 25px 0;
  /* border-radius: 20px; */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tab:hover {
  background: rgba(232, 235, 235, 0.54);
  color: #FF8217;
}

.active {
  background: linear-gradient(90deg, #F4F6F5 49.86%, rgba(244, 246, 245, 0) 100%);
border-left: 3px solid #FF8217;
  /* color: white; */
}

.tab-icon {
  width: 35px;
  margin-left: 35px;
  fill: #FF8217 !important;
}

.tab-name {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  margin-left: 20px;
  color:#5F6562;
}
.tab:hover .tab-icon {
  transition: fill 0.2s ease-in-out;
  fill: #FF8217 !important;
}
</style>
